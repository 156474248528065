const $doc = $(document)

function init(classname) {
  if ( !$(classname).length ) 
    return false

  const isYoutube = $(classname)[0].href.includes('youtube')
  let options = {
    mainClass: 'popup--animate',
    callbacks: {
      open: function() {
        $('body').addClass('popup-open')
      },
      close: function() {
        $('body').removeClass('popup-open')
      }
    }
  }
  const iframe = {
		patterns: {
			youtube: {
				index: 'youtube.com',
				id: 'v=',
				src: 'https://www.youtube.com/embed/%id%?rel=0&autoplay=1&mute=1'
			}
		}
  }
console.log( isYoutube )
console.log(options)
  options.iframe = isYoutube ? iframe : {}
  options.type = isYoutube ? 'iframe' : 'inline'

  if (classname) {
    $(classname).magnificPopup(options)
  }
}

export default function initPopup(classname){
  $doc.ready( init(classname) )
}
